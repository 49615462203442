import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    return (
        <footer>
            <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-gray-200 py-8">

                {/* Top area: Blocks */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 py-8">

                    {/* 1st block */}
                    <div className="box-border border-b-4 border-blue-900 p-8 bg-gray rounded-lg xl:w-80 mx-auto text-center md:text-left">
                        <h3 className="font-bold text-4xl mb-4">Aryahi Optinet</h3>
                        <p className="text-gray-600">
                            Guwahati, Assam
                        </p>
                    </div>

                    {/* 2nd block */}
                    <div className="justify-center items-center text-center ">
                        <h6 className="text-[#013289] text-xl text-center font-bold mb-4">LINKS</h6>
                        <ul className="space-y-2 items-center">
                            <li>
                                <HashLink to="/" className="text-[#013289] hover:text-gray-900 transition duration-250 ease-in-out no-underline">
                                    About
                                </HashLink>
                            </li>
                            <li>
                                <HashLink to="/services" className="text-[#013289] hover:text-gray-900 transition duration-250 ease-in-out no-underline">
                                    Services
                                </HashLink>
                            </li>
                            <li>
                                <HashLink to="/contact" className="text-[#013289] hover:text-gray-900 transition duration-250 ease-in-out no-underline">
                                    Contact
                                </HashLink>
                            </li>
                        </ul>
                    </div>

                    {/* 3rd block */}
                    <div className="justify-center items-center text-center">
                        <h6 className="text-[#013289] text-xl font-bold mb-4 justify-center">OUR SERVICES</h6>
                        <ul className="space-y-2 items-center">
                            <li>
                                <Link to="#" className="text-[#013289] hover:text-gray-900 transition no-underline">
                                    Networking & Security
                                </Link>
                            </li>
                            <li>
                                <Link to="#" className="text-[#013289] hover:text-gray-900 transition no-underline">
                                    Data Centre Setup and Operations
                                </Link>
                            </li>
                            <li>
                                <Link to="#" className="text-[#013289] hover:text-gray-900 transition no-underline">
                                    Domain and Hosting
                                </Link>
                            </li>
                            <li>
                                <Link to="#" className="text-[#013289] hover:text-gray-900 transition no-underline">
                                    Digital Signage Solutions
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                

                {/* Bottom area */}
                <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                    <div className="text-sm text-gray-200 font-semibold py-1">
                    Copyright &copy; {new Date().getFullYear()}{"  "}
                    <HashLink
                        to="#"
                        className=" hover:text-gray-900"
                    >
                        Aryahi Optinet
                    </HashLink>. All rights reserved.
                    </div>
                </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
