import React from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
// import imageSrc from '../assets/your-image.jpg';
import { useDocTitle } from "../components/CustomHook";
import { Link } from "react-router-dom"; // Ensure you're using react-router-dom for navigation

const Network = () => {
  useDocTitle("Networking and Security - Project Overview");

  return (
    <>
      <NavBar />
      {/* Hero Section */}
      <div className="bg-gray-50 py-20">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            {/* Left Side: Content */}
            <div>
              <h1 className="text-3xl font-bold text-blue-900 uppercase">
                Networking and Security
              </h1>
              <p className="text-gray-700 mt-6 leading-relaxed">
                At Aryahi Optinet, we offer 24x7 network monitoring services to
                help you keep your business running smoothly with optimum
                performance. Our team of experts is dedicated to resolving
                issues within a predefined SLA.
              </p>
              <p className="text-gray-700 mt-4 leading-relaxed">
              <h2 className="font-semibold mt-4">
                Network Security Operations and Cybersecurity Support:
              </h2>
                We provide high-speed Internet Lease services ranging from 1
                Mbps to 100 Mbps to help our customers run their businesses
                seamlessly. Experience reliability and efficiency in internet
                connectivity with our Enterprise Broadband and Internet Leased
                Line services.
              </p>
           
            </div>

            {/* Right Side: Image */}
            <div className="flex justify-center">
              <img
                src="/images/netsecurity.avif" 
                alt="Networking and Security"
                className="rounded-lg shadow-lg w-full max-w-md"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Project Overview Section */}
      <div className="bg-gray-50 py-12">
        <div className="container mx-auto px-4">
          <section className="bg-white shadow-lg rounded-lg p-8 mb-12">
            
              <h2 className="font-semibold mt-4">
                Network Security Operations and Cybersecurity Support:
              </h2>
              <p className="text-gray-700 mt-4 leading-relaxed">
              We offer Security Operations and Cybersecurity Support services
              to help businesses build security and incident response
              capabilities. We perform end-to-end security audits and help
              customers implement standard security controls (As per ISMS/IEC
              27001:2022) to protect against cyberattacks and other external
              security threats.
            </p>
          </section>

          {/* Conclusion Section */}
          <section className="bg-white shadow-lg rounded-lg p-8 mb-8">
            <h2 className="text-2xl font-semibold text-blue-800 border-b-2 border-blue-500 pb-2">
              Conclusion
            </h2>
            <p className="text-gray-700 mt-4 leading-relaxed">
              The Networking and Security project demonstrates a practical
              approach to creating secure and efficient networking solutions.
              By leveraging modern technologies, this project addresses
              real-world challenges in data security and infrastructure
              management.
            </p>

            {/* Button */}
            <div className="mt-6 text-center">
              <Link
                to="/contact"
                className="inline-block bg-blue-900 text-white text-lg font-semibold px-6 py-3 rounded-lg hover:bg-blue-800 transition"
              >
                For More Details
              </Link>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Network;
