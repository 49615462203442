import React, { useState } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";

const CareerPortal = () => {
  useDocTitle("Aryahi Optinet - Career Portal");

  // State to manage the visibility of the form
  const [showForm, setShowForm] = useState(false);

  const handleApplyNowClick = () => {
    setShowForm(true); // Show the form when Apply Now is clicked
  };

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="career-portal"
        className="flex justify-center items-center min-h-screen bg-gray-50 py-12 lg:py-24"
      >
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="fade-up"
        >
          <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-10/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-lg">
            <div className="flex flex-col items-center lg:items-start">
              <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-3xl lg:text-4xl">
                Join Our Team at Aryahi Optinet
              </h1>
              <p className="text-lg text-gray-600 mt-4 text-center lg:text-left">
                We are a fast-growing company based in Guwahati, Assam, offering exciting opportunities in networking, security, and digital solutions. Apply today and be part of our dynamic team.
              </p>
            </div>

            <div className="mt-12">
              {/* Job Openings */}
              <h2 className="text-xl font-semibold text-blue-900">Current Openings</h2>
              <ul className="space-y-4 mt-6">
                <li className="bg-gray-100 p-4 rounded-lg shadow">
                  <h3 className="text-lg font-bold text-blue-900">Networking Engineer</h3>
                  <p className="text-sm text-gray-600">Location: Guwahati, Assam</p>
                  <p className="mt-2 text-gray-600">We are looking for a skilled networking engineer to manage our infrastructure and ensure network security.</p>
                  <button
                    className="bg-blue-900 text-white font-semibold py-2 px-6 mt-4 rounded-lg hover:bg-blue-700 transition duration-300"
                    onClick={handleApplyNowClick} 
                  >
                    Apply Now
                  </button>
                </li>
                <li className="bg-gray-100 p-4 rounded-lg shadow">
                  <h3 className="text-lg font-bold text-blue-900">Security Analyst</h3>
                  <p className="text-sm text-gray-600">Location: Guwahati, Assam</p>
                  <p className="mt-2 text-gray-600">We are seeking a security analyst to oversee and enhance the security posture of our company’s IT infrastructure.</p>
                  <button
                    className="bg-blue-900 text-white font-semibold py-2 px-6 mt-4 rounded-lg hover:bg-blue-700 transition duration-300"
                    onClick={handleApplyNowClick} 
                  >
                    Apply Now
                  </button>
                </li>
                {/* Add more jobs as necessary */}
              </ul>
            </div>

            {showForm && ( // Show the form only when "Apply Now" is clicked
              <div className="mt-12">
                {/* Job Application Form */}
                <h2 className="text-xl font-semibold text-blue-900">Apply for a Position</h2>
                <p className="text-sm text-gray-600 mt-4">
                  Please fill out the form below to apply for the position you're interested in. We will review your application and get back to you.
                </p>

                <form className="mt-8 space-y-6">
                  <div className="flex flex-col">
                    <label className="text-lg text-blue-900" htmlFor="name">
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="border rounded-lg p-3 mt-2"
                      placeholder="Enter your full name"
                      required
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="text-lg text-blue-900" htmlFor="email">
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="border rounded-lg p-3 mt-2"
                      placeholder="Enter your email"
                      required
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="text-lg text-blue-900" htmlFor="phone">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      className="border rounded-lg p-3 mt-2"
                      placeholder="Enter your phone number"
                      required
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="text-lg text-blue-900" htmlFor="position">
                      Position Applied For
                    </label>
                    <input
                      type="text"
                      id="position"
                      name="position"
                      className="border rounded-lg p-3 mt-2"
                      placeholder="Enter the job title"
                      required
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="text-lg text-blue-900" htmlFor="resume">
                      Upload Resume
                    </label>
                    <input
                      type="file"
                      id="resume"
                      name="resume"
                      className="border rounded-lg p-3 mt-2"
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="bg-blue-900 text-white font-bold py-3 px-6 rounded-lg mt-4 hover:bg-blue-700 transition duration-300"
                  >
                    Submit Application
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CareerPortal;
