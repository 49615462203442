import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import logo from '../../images/aryahi-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faBoxOpen, faPhone, faBriefcase, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

const NavBar = () => {
  const [top, setTop] = useState(!window.scrollY);
  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen); // Toggle the sidebar
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen); // Toggle dropdown on click
  };

  useEffect(() => {
    const scrollHandler = () => {
      setTop(window.pageYOffset <= 10);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  return (
    <nav className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out ${!top && "bg-white shadow-md"}`}>
      <div className="w-full bg-gray-100 py-0 mx-auto">
        <div className="flex justify-between items-center px-1 md:px-6 py-1">
          <Link to ="/">
          <img alt="aryahi optinet" className="h-17 w-80" src={logo} />
          </Link>
          
          {/* Sidebar Toggle Button */}
          <button className="text-blue-900 p-2 lg:hidden" onClick={toggleSidebar}>
          </button>

          {/* Hamburger Menu */}
          <button
            className="p-2 rounded-md lg:hidden text-blue-900"
            onClick={handleClick}
          >
            <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>

          {/* Desktop Menu */}
          <div className="hidden lg:flex space-x-20 p-2 transition-colors duration-300">
            <HashLink smooth to="/services" className="text-lg font-semibold display-inline text-blue-600 hover:text-black-900 hover:bg-blue-100 no-underline transition-colors duration-300">
              Services
            </HashLink>
            <HashLink smooth to="/portfolio" className="text-lg font-semibold text-blue-600 hover:text-black-900 hover:bg-blue-100 no-underline transition-colors duration-300">
              Solutions
            </HashLink>

            {/* Dropdown Menu */}
            <div className="relative">
              <button
                className="text-lg font-semibold text-blue-600 hover:text-black-900 hover:bg-blue-100 no-underline transition-colors duration-300"
                onClick={toggleDropdown}
              >
                About Us
              </button>

              {/* Dropdown Content */}
              {dropdownOpen && (
                <div className="absolute flex flex-col bg-white shadow-lg mt-4 left-1/2 transform -translate-x-1/2 p-6 rounded-md z-50 space-y-4">
                  <ul className="space-y-4">
                    <li>
                      <HashLink
                        smooth
                        to="/location"
                        className="block px-4 py-2 text-blue-900 hover:bg-blue-100 hover:text-black transition-colors duration-300 no-underline"
                      >
                        Location
                      </HashLink>
                    </li>
                    <li>
                      <HashLink
                        smooth
                        to="/career"
                        className="block px-4 py-2 text-blue-900 hover:bg-blue-100 hover:text-black transition-colors duration-300 no-underline"
                      >
                        Career Portal
                      </HashLink>
                    </li>
                    <li>
                      <HashLink
                        smooth
                        to="/contact"
                        className="block px-4 py-2 text-blue-900 hover:bg-blue-100 hover:text-black transition-colors duration-300 no-underline"
                      >
                        Contact Us
                      </HashLink>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <HashLink smooth to="/get-demo" className="bg-gradient-to-r from-blue-700 to-blue-600 text-white py-2 px-6 rounded-lg shadow-lg transform hover:scale-105 hover:from-blue-600 hover:to-blue-700 transition-all duration-300 ease-in-out no-underline">
              Demo our Products
            </HashLink>
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="bg-white lg:hidden shadow-md py-2 top-0 left-0 w-full h-full">
            <div className="flex flex-col bg-gray-100 space-y-4 px-6 py-4">
              <HashLink smooth to="/" className="block px-4 py-2 text-blue-900 hover:bg-blue-100 hover:text-black transition-colors duration-300 no-underline">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              About</HashLink>
              <HashLink smooth to="/get-demo" className="block px-4 py-2 text-blue-900 hover:bg-blue-100 hover:text-black transition-colors duration-300 no-underline ">
              <FontAwesomeIcon icon={faBoxOpen} className="mr-2" />
              Our Products</HashLink>
              <HashLink smooth to="/contact" className="block px-4 py-2 text-blue-900 hover:bg-blue-100 hover:text-black transition-colors duration-300 no-underline">
              <FontAwesomeIcon icon={faPhone} className="mr-2" />
              Contact</HashLink>
              <HashLink smooth to="/career" className="block px-4 py-2 text-blue-900 hover:bg-blue-100 hover:text-black transition-colors duration-300 no-underline">
              <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
              Career Portal</HashLink>
              <HashLink smooth to="/location" className="block px-4 py-2 text-blue-900 hover:bg-blue-100 hover:text-black transition-colors duration-300 no-underline">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
              Location</HashLink>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
