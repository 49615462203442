import React from 'react';
import ala from '../images/clients/ala.png';
import geps from '../images/clients/geps.png';
import protergia from '../images/clients/protergia.png';

const clientImage = {
    height: '10rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
}

const Clients = () => {
    return (
        <div className="mt-8 bg-gray-100">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Clients</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-lg font-semibold text-blue-900">Some of our clients.</h2>
                </div>

                <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3 gap-2">
                        <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out opacity-70 hover:opacity-100 w-full">
                            <img src={ala} alt="client" />
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-70 hover:opacity-100 w-full">
                            <img src={protergia} alt="client" />
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-70 hover:opacity-100 w-full">
                            <img src={geps} alt="client" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Clients;
