import React, { useState, useEffect } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";

const ContactForm = () => {
  useDocTitle("Aryahi Optinet - Contact Us");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [captchaNumbers, setCaptchaNumbers] = useState({ num1: 0, num2: 0 });
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  // Generate new CAPTCHA numbers on mount
  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10); // Random number 0-9
    const num2 = Math.floor(Math.random() * 10); // Random number 0-9
    setCaptchaNumbers({ num1, num2 });
    setCaptchaVerified(null); // Reset verification status
    setCaptchaInput(""); // Clear input
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCaptchaChange = (e) => {
    setCaptchaInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const correctAnswer = captchaNumbers.num1 + captchaNumbers.num2;

    if (parseInt(captchaInput, 10) === correctAnswer) {
      setCaptchaVerified(true);
      setSubmitted(true);
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      generateCaptcha(); // Refresh CAPTCHA after successful submission
    } else {
      setCaptchaVerified(false);
      generateCaptcha(); // Generate a new CAPTCHA on failure
    }
  };

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="contact-form"
        className="flex justify-center items-center min-h-screen bg-gradient-to-r from-light-blue-400 via-blue-600 to-dark-blue-800 py-12 lg:py-24"
      >
        <div
          className="container mx-auto px-4 lg:px-20"
          data-aos="fade-up"
        >
          <div className="w-full bg-white p-8 rounded-3xl shadow-lg max-w-lg mx-auto">
            <h1 className="font-bold text-center text-blue-900 text-3xl lg:text-4xl">
              Contact Us
            </h1>
            <p className="text-lg text-gray-600 mt-4 text-center">
              We’d love to hear from you! Please fill out the form below and we will get back to you as soon as possible.
            </p>

            {submitted ? (
              <div className="mt-8 text-center text-green-500">
                <h2>Thank you for reaching out!</h2>
                <p>We'll get back to you shortly.</p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                {/* Form Fields */}
                <div className="flex flex-col">
                  <label className="text-lg text-blue-900" htmlFor="name">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="border rounded-lg p-4 mt-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
                    placeholder="Enter your full name"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-lg text-blue-900" htmlFor="email">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="border rounded-lg p-4 mt-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-lg text-blue-900" htmlFor="phone">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="border rounded-lg p-4 mt-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
                    placeholder="Enter your phone number"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-lg text-blue-900" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="border rounded-lg p-4 mt-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
                    placeholder="Enter your message"
                    required
                    rows="5"
                  />
                </div>

                {/* CAPTCHA Section */}
                <div className="flex flex-col mt-4">
                  <label className="text-blue-900 text-center font-bold font-barcelony text-2xl">CAPTCHA</label>
                  <div className="text-lg font-bold bg-gray-200 p-3 rounded-lg mt-2 text-center select-none">
                     {captchaNumbers.num1} + {captchaNumbers.num2}=
                  </div>
                  <input
                    type="text"
                    value={captchaInput}
                    onChange={handleCaptchaChange}
                    className="border rounded-lg p-4 mt-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
                    placeholder="Enter the answer"
                    required
                  />
                  {captchaVerified === false && (
                    <p className="text-red-500 mt-2">Incorrect answer. CAPTCHA has been updated. Please try again.</p>
                  )}
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="bg-blue-900 text-white font-bold py-3 px-6 rounded-lg mt-4 hover:bg-gradient-to-r hover:from-light-blue-500 hover:to-dark-blue-700 transition duration-300 hover:shadow-lg"
                >
                  Send Message
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactForm;
