import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import Location from './pages/Location';
import DemoProduct from './pages/DemoProduct';
import CareerPortal from './pages/Career';

// Components
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Network from './components/Network';

function App() {
  // Set page title using a custom hook
  useDocTitle("Aryahi Optinet | ISP | System Integrations");

  // Initialize AOS (Animate on Scroll) library
  useEffect(() => {
    AOS.init({
      duration: 1000,  // Animation duration (in ms)
      easing: 'ease-out-cubic',  // Easing function
    });
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/location" element={<Location />} />
          <Route path="/get-demo" element={<DemoProduct />} />
          <Route path="/services" element={<Services />} />
          <Route path="/career" element={<CareerPortal />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/network" element={<Network />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
